import React from "react";
import "./index.css";
import logo from "../../assets/logo.svg";
import { Outlet } from "react-router-dom";
import SideMenu from "./components/SideMenu/SideMenu.jsx";

const Admin = () => {
  return (
    <section className="admin">
      <header>
        <a href="/">
          <img
            src={logo}
            alt="EcoVanguard's club logo"
            className="admin__logo"
          />
        </a>
      </header>
      {/* <SideMenu /> */}
      <Outlet />
    </section>
  );
};
export default Admin;
