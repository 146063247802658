import { DataGrid } from "@mui/x-data-grid";
import { ReactComponent as EditIcon } from "../../../../assets/admin-edit-icon.svg";
import { ReactComponent as DeleteIcon } from "../../../../assets/admin-delete-icon.svg";

const columns = [
  { field: "id", headerName: "ID", width: 70 },
  { field: "firstName", headerName: "First name", width: 130 },
  { field: "lastName", headerName: "Last name", width: 130 },
  {
    field: "age",
    headerName: "Age",
    type: "number",
    width: 90,
  },
  {
    field: "fullName",
    headerName: "Full name",
    description: "This column has a value getter and is not sortable.",
    sortable: false,
    width: 160,
    valueGetter: (params) =>
      `${params.row.firstName || ""} ${params.row.lastName || ""}`,
  },
];

const rows = [
  { id: 1, lastName: "Snow", firstName: "Jon", age: 35 },
  { id: 2, lastName: "Lannister", firstName: "Cersei", age: 42 },
  { id: 3, lastName: "Lannister", firstName: "Jaime", age: 45 },
  { id: 4, lastName: "Stark", firstName: "Arya", age: 16 },
  { id: 5, lastName: "Targaryen", firstName: "Daenerys", age: null },
  { id: 6, lastName: "Melisandre", firstName: null, age: 150 },
  { id: 7, lastName: "Clifford", firstName: "Ferrara", age: 44 },
  { id: 8, lastName: "Frances", firstName: "Rossini", age: 36 },
  { id: 9, lastName: "Roxie", firstName: "Harvey", age: 65 },
];

function DataTable() {
  const actionCol = [
    {
      field: `edit-action`,
      headerName: `edit`,
      width: 100,
      renderCell: () => {
        return <EditIcon />;
      },
    },
    {
      field: `delete-action`,
      headerName: `delete`,
      width: 100,
      renderCell: () => {
        return <DeleteIcon />;
      },
    },
  ];
  return (
    <div
      style={{
        height: 400,
        width: "100%",
        paddingInline: "20px",
      }}
    >
      <DataGrid
        rows={rows}
        columns={[...columns, ...actionCol]}
        pageSize={7}
        sx={{
          borderRadius: "30px",
          boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.15)",
        }}
        rowsPerPageOptions={[7]}
        checkboxSelection
      />
      <br />
    </div>
  );
}
export default DataTable;
