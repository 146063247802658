import React from "react";
import ButtonLink from "../Button/button";
import styles from "./style.module.css";
import { Link } from "react-router-dom";
import { Heading, Text, Button } from "@chakra-ui/react";
import {Zoom, Slide, Fade} from 'react-reveal'

function ImgMsgBtn({
  header,
  msgBtn,
  msgBody1,
  msgBody2,
  btnLink = "",
  criteria,
  image,
  swap,
}) {
  swap = swap
    ? `${styles.section_container_swap}`
    : `${styles.section_container}`;
  return (
    <div className={styles.section_wrapper}>
      <Heading className={styles.header} mb='16'>{header}</Heading>
      <div className={swap}>
        <div className={styles.section_message}>
          <Slide left>
          <div className={styles.msgBody}>
            <Text mb='3' fontSize={18} lineHeight={8}>{msgBody1}</Text>
            <Text mb='3' fontSize={18} lineHeight={8}>
              {msgBody2} <a href="#">{criteria}</a>
            </Text>
          </div>
        </Slide>
          <div className={styles.msgBtn}>
            <Link to={btnLink}>
              <Button bg='#0397d6' color='white' size='lg'>{msgBtn}</Button>
            </Link>
          </div>
        </div>
        <Zoom>
        <div className={styles.section_img}>
          <img src={image} alt="Waste Disposal"></img>
        </div>
        </Zoom>
      </div>
    </div>
  );
}

export default ImgMsgBtn;
