import React from "react";
import "./index.css";
import contactSrc from "../../assets/eco-contact.svg";
import InputComponent from "./../../components/Input/input";
import ButtonLink from "../../components/Button/button";
import { FaInstagram, FaLinkedin } from "react-icons/fa";
import { useQuery } from "react-query";
import { Heading, Box, Text, Input, Button, FormLabel, Textarea } from "@chakra-ui/react";
import Fade from 'react-reveal/Fade'
import Zoom from "react-reveal/Zoom";
import Slide from "react-reveal/Slide";

const Contact = () => {
  const handleQuery = async (ev) => {
    const URL = `https://jsonplaceholder.typicode.com/posts`;

    let response = await fetch(URL, {
      method: "POST",
      body: new FormData(ev.target),
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response.text();
  };
  const { isLoading, isError, data, error, refetch } = useQuery(
    "contact-form",
    handleQuery,
    {
      refetchOnWindowFocus: false,
      enabled: false, // stops the query from running automatically
    }
  );
  const handleSubmit = (ev) => {
    ev.preventDefault();
    refetch();
    ev.target.reset();
  };
  if (isError) return <div>{error}</div>;
  return (
    <Box className="contact" mt="8">
      <div className="contact__form">
        <Zoom><Heading mb="5">Contact Us</Heading></Zoom>
        <h1>
          {isLoading} {isError} {data}
        </h1>
        <Fade left>
        <Text fontSize={18} lineHeight={8}>
          In doubt of any issue or want to lodge a complaint? Or do you simply
          want to inform us? Let us know what you think. We would love to hear
          from you.{" "}
        </Text>
        </Fade>
        <br />
        <Slide left>
        <form onSubmit={handleSubmit}>
          <Box mb="5">
            <FormLabel htmlFor="email">Your Email</FormLabel>
            <Input
              placeholder="Enter your email address"
              type="email"
              id="email"
              name="email"
              required
            />
          </Box>

          <div>
            <FormLabel htmlFor="comment">what do you need help with</FormLabel>
            <Textarea
              id="comment"
              name="comment"
              placeholder="Enter what you need help with"
              maxLength={300}
              h="180px"
              required
              resize='none'
            />
          </div>
          <Button type="submit" w="100%" my="3" bg="#0397d6" color='white'>
            Send Message
          </Button>
          <div className="contact__policy">
            <input type="checkbox" name="policy" id="policy" required />
            <label htmlFor="policy">
              <Text fontSize={15 }>
                I agree with the <a href="/policy">privacy policy</a>
              </Text>
            </label>
          </div>
        </form>
        </Slide>
        {/* <div className="contact__links"> */}
          
            <Text mt='4' fontSize={20} w='100%'>Reach out and follow us on social media</Text>
          
        {/* </div> */}
        <div className="contact__icons">
          <br />
          <br />
          <i>
            <a href="https://www.linkedin.com/company/ecovanguard-club-ui/">
              <FaLinkedin />
            </a>
          </i>
          <i>
            <a href="https://www.instagram.com/ui_ecovanguard_club/">
              <FaInstagram />
            </a>
          </i>
          <br />
          <br />
        </div>
      </div>
      <Zoom>
      <div className="contact__image">
        <img
          src={contactSrc}
          alt="Contact us illustration"
          width="500"
          height="500"
        />
      </div>
      </Zoom>
    </Box>
  );
};

export default Contact;
